import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyBdLvcRcMbqPhcZqb14vjlphEROCKXuMI8",
  authDomain: "muhabbetsofrasi-a07d6.firebaseapp.com",
  projectId: "muhabbetsofrasi-a07d6",
  storageBucket: "muhabbetsofrasi-a07d6.appspot.com",
  messagingSenderId: "949338084548",
  appId: "1:949338084548:web:680a262b416cc58885e591",
  measurementId: "G-NR6MNCFN9J"
};
// Initialize Firebase
const fb = firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export { fb, db };
