import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/dashboard/home";
import Settings from "../components/dashboard/settings";
import Menuler from "../components/admin/menuler";
import Menu from "../components/seller/menuler";
import Urunler from "../components/admin/urunler";
import Tatlilar from "../components/admin/tatlilar";
import Tatli from "../components/seller/tatlilar";
import Urun from "../components/seller/urunler";
import Soslar from "../components/admin/soslar";
import Sos from "../components/seller/soslar";
import Icecekler from "../components/admin/icecekler";
import Icecek from "../components/seller/icecekler";
import Dashboard from "../components/seller/dashboard";
import Login from "../components/login/login";
import BayiAnasayfa from "../components/seller/bayiAnasayfa.vue";
import Siparisler from "../components/seller/siparisler.vue";
import Musteri from "../components/musteri/musteri";
import MusteriLogin from "../components/musteri/login";
import Bayiler from "../components/admin/bayiler";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  
  {
    path: "/home",
    name: "home",
    component: Home,
    children: [
      {
        path: "menuler",
        name: "menuler",
        component: Menuler
      },
      {
        path: "urunler",
        name: "urunler",
        component: Urunler
      },
      {
        path: "tatlilar",
        name: "tatlilar",
        component: Tatlilar
      },
      {
        path: "icecekler",
        name: "icecekler",
        component: Icecekler
      },
      {
        path: "soslar",
        name: "soslar",
        component: Soslar
      },
      {
        path: "settings",
        name: "settings",
        component: Settings
      },
      {
        path: "bayiler",
        name: "bayiler",
        component: Bayiler
      }
    ]
  },
  {
    path: "/dashboard/:id",
    component: Dashboard,
    children: [
      {
        path: "home",
        name: "home",
        component: BayiAnasayfa
      },
      {
        path: "menu",
        name: "menu",
        component: Menu
      },
      {
        path: "urun",
        name: "urun",
        component: Urun
      },
      {
        path: "tatli",
        name: "tatli",
        component: Tatli
      },
      {
        path: "sos",
        name: "sos",
        component: Sos
      },
      {
        path: "siparis",
        name: "siparis",
        component: Siparisler
      },
      {
        path: "icecek",
        name: "icecek",
        component: Icecek
      },
    ]
  },
  {
    path: "/customer-login/:id",
    name: "customer-login",
    component: MusteriLogin
  },
  {
    path: "/customer-shopping/:id",
    name: "shopping",
    component: Musteri
  },
  
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
