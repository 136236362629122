<template>
  <div>
    <v-row>
      <v-col lg="10" md="12" sm="12" cols="6">
        <div class="font-weight-medium mt-4">
          MENÜLER
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-snackbar
        class="mt-10"
        v-model="snackbar.status"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        top
        right
      >
        {{ snackbar.text }}
        <v-btn dark text icon @click="snackbar.status = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-snackbar>
      <v-col
        cols="6"
        sm="6"
        md="4"
        lg="3"
        v-for="product in products"
        :key="product.id"
      >
        <v-card tile>
          <v-img
            class="white--text align-end productImage"
            :src="product.resim"
          ></v-img>
          <v-card-text class="font-weight-black">
            <v-row no-gutters class="mb-2 productImageRow">
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="12">
                    <h3 class="text-center productUrunadi">
                      {{ product.urunadi }}
                    </h3>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters>
              <v-col cols="12" class="mt-2">
                <v-row no-gutters style="margin-top: -20px;">
                  <v-col cols="6" class="mt-4 text-center">
                    <a @click="fiyatDuzenle(product)">
                      <h2>
                        <strong
                          class="font-weight-black productFiyat"
                          style="font-weight: older;"
                        >
                          {{ product.fiyat }} {{ product.paraBirimi }}
                        </strong>
                      </h2>
                    </a>
                  </v-col>
                  <v-col cols="6">
                    <v-row no-gutters>
                      <v-col
                        cols="4"
                        offset="4"
                        class="float-right mt-5 productActive"
                      >
                        {{ product.activeDurum }}
                      </v-col>
                      <v-col cols="4" class="float-right">
                        <v-switch
                          v-model="product.active"
                          @change="selectedProduct(product)"
                          :color="renk"
                          small
                          hide-details
                          class="productSwitch"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="editDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">
          Fiyat Düzenle
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="editDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="product.fiyat"
                    label="Yeni Fiyat"
                    prepend-icon="bookmark"
                    color="sari"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    item-text="name"
                    item-value="sembol"
                    :items="paraBirimleri"
                    v-model="product.paraBirimi"
                    prepend-icon="euro"
                    label="Para Birimi"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            @click="updateProduct"
            :loading="updateLoader"
            dark
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editTitleDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">
          Başlığı Düzenle
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="editTitleDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="brosurTitle"
                    label="Yeni Başlık"
                    prepend-icon="bookmark"
                    color="sari"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="urunListDialog" max-width="700">
      <v-card>
        <v-card-title class="headline">
          Menü Listesi Düzenle
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="urunListDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="list.urun1"
                    label="Ürün 1"
                    prepend-icon="bookmark"
                    color="#d58f4c"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="list.urun1Fiyat"
                    label="Fiyat"
                    prepend-icon="bookmark"
                    color="#d58f4c"
                  ></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="list.urun2"
                    label="Ürün 2"
                    prepend-icon="bookmark"
                    color="#d58f4c"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="list.urun2Fiyat"
                    label="Fiyat"
                    prepend-icon="bookmark"
                    color="#d58f4c"
                  ></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="list.urun3"
                    label="Ürün 3"
                    prepend-icon="bookmark"
                    color="#d58f4c"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="list.urun3Fiyat"
                    label="Fiyat"
                    prepend-icon="bookmark"
                    color="#d58f4c"
                  ></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="list.urun4"
                    label="Ürün 4"
                    prepend-icon="bookmark"
                    color="#d58f4c"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="list.urun4Fiyat"
                    label="Fiyat"
                    prepend-icon="bookmark"
                    color="#d58f4c"
                  ></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="list.urun5"
                    label="Ürün 5"
                    prepend-icon="bookmark"
                    color="#d58f4c"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="list.urun5Fiyat"
                    label="Fiyat"
                    prepend-icon="bookmark"
                    color="#d58f4c"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="#d58f4c"
            dark
            @click="urunListDialog = false"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="brosurDialog" max-width="625">
      <v-card>
        <v-card-title class="headline">
          Broşür
          <v-spacer></v-spacer>
          <div id="editor"></div>
          <v-btn
            id="cmd"
            outlined
            color="success"
            class="ma-2 white--text"
            @click="generatePdf"
          >
            İNDİR
            <v-icon right dark>
              mdi-cloud-upload
            </v-icon>
          </v-btn>
          <v-btn icon color="grey" text @click="brosurDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row id="zemin"></v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { db, fb } from '../../firebase/fb'

export default {
  data() {
    return {
      urunListDialog: false,
      renk: 'success',
      chk: false,
      editTitleDialog: false,
      brosurTitle: 'MUHABBET SOFRASI CUMARTESİ MENÜ',
      checked: false,
      editDialog: false,
      disabled: true,
      products: [],
      snackbar: {
        status: false,
        text: '',
        color: '',
        timeout: 4000,
      },
      title: '',
      dialog: false,
      loader: false,
      updateLoader: false,
      product: {
        id: null,
        urunadi: '',
        urunaciklama: '',
        resim: '',
        docName: '',
        fiyat: '',
        paraBirimi: '',
      },
      paraBirimleri: [
        { name: 'CHF', sembol: 'CHF' },
        { name: 'DDK', sembol: 'DDK' },
        { name: 'NOK', sembol: 'NOK' },
        { name: 'SEK', sembol: 'SEK ' },
        { name: '£', sembol: '£ ' },
        { name: '€', sembol: '€' },
      ],
      loading: false,
      selectedProducts: [],
      brosurDialog: false,
      list: {
        urun1: 'EKMEK ARASI KAVURMA',
        urun1Fiyat: 5,
        urun2: 'POMMES ORTA',
        urun2Fiyat: 1.5,
        urun3: 'POMMES BÜYÜK',
        urun3Fiyat: 2,
        urun4: 'SÜTLAÇ',
        urun4Fiyat: 1,
        urun5: 'İÇECEK',
        urun5Fiyat: 1,
      },
      id: this.$route.params.id,
    }
  },
  beforeMount() {
    this.getProduct()
  },
  mounted() {},
  watch: {
    $route(to, from) {
      {
        this.id = to.params.id
      }
    },
  },
  methods: {
    editUrunList() {
      this.urunListDialog = true
    },
    generatePdf() {},
    downloadBrosur() {},
    editTitle() {
      this.editTitleDialog = true
    },
    newBrosur() {
      this.brosurDialog = true
    },
    selectedProduct(product) {
      //this.product = product;
      //console.log(product)
      if (product.active === true) {
        console.log(product.active)
        db.collection('bayiler')
          .doc(this.id)
          .collection('menuler')
          .doc(product.docName)
          .update({
            active: true,
            activeDurum: 'Aktif',
          })
          .then(function () {
            console.log('Document successfully updated!')
          })
          .catch(function (error) {
            console.error('Error updating document: ', error)
          })
      } else if (product.active === false) {
        // console.log(product.active)
        db.collection('bayiler')
          .doc(this.id)
          .collection('menuler')
          .doc(product.docName)
          .update({
            active: false,
            activeDurum: 'Pasif',
          })
          .then(function () {
            console.log('Document successfully updated!')
          })
          .catch(function (error) {
            console.error('Error updating document: ', error)
          })
      }
    },
    fiyatDuzenle(product) {
      this.editDialog = true
      this.product = product
    },

    editProduct(product) {
      this.title = 'Menü Güncelleme'
      this.product = product
      this.editDialog = !this.editDialog
      console.log(this.product)
    },
    getProduct() {
      db.collection('bayiler')
        .doc(this.$route.params.id)
        .collection('menuler')
        .orderBy('id', 'desc')
        .onSnapshot((querySnapshot) => {
          this.products = []
          querySnapshot.forEach((doc) => {
            this.products.push({ docName: doc.id, ...doc.data() })
          })
          console.log(this.products)
        })
    },
    updateProduct() {
      let vm = this
      vm.updateLoader = true
      db.collection('bayiler')
        .doc(vm.id)
        .collection('menuler')
        .doc(vm.product.docName)
        .update({
          urunadi: vm.product.urunadi,
          urunaciklama: vm.product.urunaciklama,
          fiyat: vm.product.fiyat,
          paraBirimi: vm.product.paraBirimi,
        })
        .then(function () {
          console.log('Document successfully updated!')
          vm.updateLoader = false
          vm.snackbar.status = true
          vm.snackbar.color = 'sari'
          vm.snackbar.text = 'Menü Bilgileri Güncellendi'
          vm.editDialog = !vm.editDialog
          vm.clearProduct()
        })
        .catch(function (error) {
          console.error('Error updating document: ', error)
        })
    },

    clearProduct() {
      this.product = {
        id: null,
        urunadi: '',
        urunaciklama: '',
        resim: '',
        paraBirimi: vm.product.paraBirimi,
      }
    },
  },
  watch: {
    brosurTitle: (val) => {
      this.brosurTitle = val
    },
  },
}
</script>
<style>
.container-bg {
  background-color: #1b0d0c;
}
.kahverengi {
  height: 25px;
  width: 5px;
  background-color: #000000;
  float: left;
}
.beyaz {
  height: 25px;
  width: 5px;
  background-color: #ffffff;
  float: left;
}
.yesil {
  background-color: #e3dd2f;
  height: 25px;
  width: 10px;
  float: left;
}
#zemin {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url('https://cdn.pixabay.com/photo/2016/03/26/13/09/organic-1280537_1280.jpg')
    no-repeat center center;
  background-size: cover;
  transform: scale(1.1);
}

@media screen and (min-width: 601px) {
  .productImage {
    height: 250px;
  }
  .productImageRow {
    height: 30px;
  }
}
@media (min-width: 300px) and (max-width: 601px) {
  .productImage {
    height: 150px;
  }
  .productImageRow {
    height: 15px;
  }
  .productUrunadi {
    font-size: small;
  }
  .productFiyat {
    font-size: large;
  }
  .productActive {
    font-size: xx-small;
  }
  .productSwitch {
    font-size: xx-small;
  }
}
</style>
