<template>
  <div id="app">
    <vue-dropzone
      ref="imgDropZone"
      id="customdropzone"
      :options="dropzoneOptions"
      @vdropzone-complete="afterComplete"
    ></vue-dropzone>
     <v-btn
              id="cmd"
              outlined
              color="success"
              class="ma-2 white--text"
              @click="makePDF"
            >
              İNDİR
              <v-icon right dark>
                mdi-cloud-upload
              </v-icon>
            </v-btn>
    <div id="capture" v-if="images.length > 0" class="image-div">
      <div v-for="image in images" :key="image.src">
        <img :src="image.src" class="image" />
      </div>
    </div>
  </div>
</template>

<script>
import { db, fb } from '../../firebase/fb' 
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { jsPDF } from 'jspdf'
import domtoimage from 'dom-to-image'
import html2canvas from 'html2canvas'
let uuid = require("uuid");
export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: false,
        acceptedFiles: ".jpg, .jpeg, .png",
        dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Drag Images or Click Here</p>
          <p class="form-text">Allowed Files: .jpg, .jpeg, .png</p>
          `
      },
      images: []
    };
  },
  methods: {
     makePDF() {
        var canvas = document.getElementById('capture')
        html2canvas(canvas).then(function (canvas) {
         var imgData = canvas.toDataURL('image/png')
         var doc = new jsPDF()
         doc.addImage(imgData, 'PNG', 10, 10)
         doc.save('menu.pdf')
       })
    },
    async afterComplete(upload) {
      var imageName = uuid.v1();
      this.isLoading = true;
      try {
        //save image
        let file = upload;
        var metadata = {
          contentType: "image/png"
        };
        var storageRef = fb.storage().ref();
        var imageRef = storageRef.child(`yeni/${imageName}.png`);
        await imageRef.put(file, metadata);
        var downloadURL = await imageRef.getDownloadURL();
        this.images.push({ src: downloadURL });
      } catch (error) {
        console.log(error);
      }
      this.$refs.imgDropZone.removeFile(upload);
    }
  }
};
</script>

<style>
.image-div {
  display: flex;
  margin: 25px;
}
.image {
  max-width: 250px;
  margin: 15px;
}
</style>