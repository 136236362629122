<template>
  <v-app id="sandbox">
    <v-navigation-drawer
      v-model="drawer"
      app
      src="../../assets/img/food-sidebar.png"
      dark
    >
      <v-list></v-list>
      <v-list rounded>
        <template>
          <v-list-item link :to="'/dashboard/' + $route.params.id + '/home'">
            <v-list-item-action>
              <v-icon>home</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Anasayfa
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="'/dashboard/' + $route.params.id + '/menu'">
            <v-list-item-action>
              <v-icon>restaurant_menu</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Menüler
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="'/dashboard/' + $route.params.id + '/urun'">
            <v-list-item-action>
              <v-icon>receipt</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Ürünler
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="'/dashboard/' + $route.params.id + '/tatli'">
            <v-list-item-action>
              <v-icon>cake</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Tatlılar
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="'/dashboard/' + $route.params.id + '/sos'">
            <v-list-item-action>
              <v-icon>gesture</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Soslar
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="'/dashboard/' + $route.params.id + '/icecek'">
            <v-list-item-action>
              <v-icon>local_cafe</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                İçecekler
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="'/dashboard/' + $route.params.id + '/siparis'">
            <v-list-item-action>
              <v-badge v-model="show" color="sari" right>
                <template v-slot:badge>
                  <span>{{ siparisSayisi }}</span>
                </template>
                <v-icon>fas fa-shopping-cart</v-icon>
              </v-badge>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Siparişler
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app flat color="#EEEEEE">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>Muhabbet Sofrası</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu transition="slide-x-transition" open-on-hover top offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar class="mr-1" size="30" color="grey darken-1">
            <v-icon dark v-bind="attrs" v-on="on">
              mdi-account-circle
            </v-icon>
          </v-avatar>
        </template>
        <v-list>
          <v-list-item-group v-model="selectedItem" color="indigo">
            <v-list-item>
              <v-list-item-title>
                <a
                  style="text-decoration-none; color:black;"
                  @click="newMusteriLink"
                >
                  Müşteri Linki
                </a>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <a
                  style="text-decoration-none; color:black;"
                  @click="hesapBilgileri"
                >
                  Hesap Bilgileri
                </a>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <a style="text-decoration-none; color:black;" @click="cikisYap">
                  Çıkış Yap
                </a>
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-content class="grey lighten-3">
      <v-snackbar
        class="mt-10"
        v-model="snackbar.status"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        top
        right
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-icon color="white" text v-bind="attrs" @click="snackbar = false">
            fas fa-times
          </v-icon>
        </template>
      </v-snackbar>
      <v-container fluid color="#EEEEEE">
        <router-view></router-view>
        <v-dialog v-model="uyeBilgileriDialog" max-width="500">
          <v-card>
            <v-card-title class="headline">
              Hesap Bilgilerini Düzenle
              <v-spacer></v-spacer>
              <v-btn icon color="grey" text @click="uyeBilgileriDialog = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="user.mail"
                    label="Mail Adresi"
                    prepend-icon="mail"
                    color="#d58f4c"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="user.username"
                    label="Kullanıcı Adı"
                    prepend-icon="mdi-account"
                    color="#d58f4c"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="user.password"
                    label="Şifre"
                    prepend-icon="mdi-lock"
                    color="#d58f4c"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="mr-4 mb-3"
                color="#d58f4c"
                dark
                @click="uyeBilgiUpdate"
                :loading="loaderUyeBilgi"
              >
                Kaydet
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-content>
    <v-footer :inset="footer.inset" color="#EEEEEE" app>
      <v-spacer></v-spacer>
      <span class="px-4" style="color: #5e4f2a;">
        &copy; {{ new Date().getFullYear() }}
        <a
          @click="newMusteriLink"
          style="text-decoration: none; color: #615454;"
          target="_blank"
        >
          Muhabbet Sofrası Müşteri Yazılımı
        </a>
      </span>
      <v-spacer></v-spacer>
    </v-footer>
  </v-app>
</template>

<script>
import { db, fb } from '../../firebase/fb'
import EventBus from '../event-bus'
import Vue from 'vue'
import Zendesk from '@dansmaculotte/vue-zendesk'
Vue.use(Zendesk, {
  key: '0397d295-1910-4940-a404-c4c6b52cb723',
  disabled: false,
  hideOnLoad: false,
  settings: {
    webWidget: {
      color: {
        theme: '#78a300',
      },
    },
  },
})
export default {
  props: {
    source: String,
    veri: 5,
  },
  data: () => ({
    selectedItem: 1,
    id: '',
    snackbar: {
      status: false,
      text: '',
      color: '',
      timeout: 4000,
    },
    loaderUyeBilgi: false,
    uyeBilgileriDialog: false,
    user: {
      username: '',
      password: '',
      id: '',
      docName: '',
      mail: '',
    },
    siparisler: [],
    drawer: null,
    footer: {
      inset: true,
    },
    musteri: {
      mail: '',
      docName: '',
      username: '',
      password: '',
    },
    musteriBilgi: [],
    testingCode: '',
    siparisSayisi: null,
  }),

  mounted() {
    this.getSiparis()
    console.log('siparisSayısı' + this.siparisler.length)
    this.siparisSayisi = this.siparisler.length
    // eventBus.$on('changedData', (val) => {
    //   this.musteriBilgi = val
    //   console.log(this.musteriBilgi);
    //   this.musteri.docName = this.musteriBilgi[0].docName
    //   this.musteri.username = this.musteriBilgi[0].username
    //   this.musteri.password = this.musteriBilgi[0].password
    //   this.musteri.mail = this.musteriBilgi[0].mail
    // })
  },
  methods: {
    newMusteriLink() {
      setTimeout(() => {
        this.$router.push({ path: `/customer-login/${this.$route.params.id}` })
      }, 1000)
    },
    uyeBilgiUpdate() {
      let vm = this
      vm.loaderUyeBilgi = true
      db.collection('bayiler')
        .doc(vm.user.docName)
        .update({
          username: vm.user.username,
          password: vm.user.password,
          mail: vm.user.mail,
        })
        .then(function () {
          console.log('Document successfully updated!')
          vm.loaderUyeBilgi = false
          vm.snackbar.status = true
          vm.snackbar.color = 'sari'
          vm.snackbar.text = 'Oturum Bilgileri Güncellendi'
          vm.uyeBilgileriDialog = false
        })
        .catch(function (error) {
          console.error('Error updating document: ', error)
        })
    },
    hesapBilgileri() {
      this.uyeBilgileriDialog = true
      db.collection('bayiler')
        .where('docName', '==', this.$route.params.id)
        .onSnapshot((querySnapshot) => {
          this.users = []
          querySnapshot.forEach((doc) => {
            ;(this.user.id = doc.data().id),
              (this.user.docName = doc.id),
              (this.user.username = doc.data().username),
              (this.user.mail = doc.data().mail),
              (this.user.password = doc.data().password)
          })
        })
    },
    cikisYap() {
      setTimeout(() => {
        this.$router.push('/')
      }, 1000)
    },
    openSiparisler() {},
    getSiparis() {
      db.collection('bayiler')
        .doc(this.$route.params.id)
        .collection('siparisler')
        .where('teslimatDurumu', '==', 'Hazırlanıyor')
        .onSnapshot((querySnapshot) => {
          this.siparisler = []
          querySnapshot.forEach((doc) => {
            this.siparisler.push({ docName: doc.id, ...doc.data() })
          })
          this.siparisSayisi = this.siparisler.length
        })
    },
  },
}
</script>
