<template>
  <v-app id="inspire">
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="primary"
      dark
    >
      <v-toolbar-title class="white--text" style="width: 350px;">
        Muhabbet Sofrası
      </v-toolbar-title>
      <v-text-field
        flat
        solo-inverted
        hide-details
        prepend-inner-icon="mdi-magnify"
        label="Arama Yap"
        class="hidden-sm-and-down pl-10 ml-4"
        v-model="searchProductKey"
      />
      <v-spacer />
      <!--
      <v-btn icon>
        <v-badge
          content="2"
          value="2"
          color="green"
          overlap
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
      -->
      <v-menu transition="slide-x-transition" open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar class="mr-1" size="30" color="grey darken-1">
            <v-icon dark v-bind="attrs" v-on="on">
              mdi-account-circle
            </v-icon>
          </v-avatar>
        </template>
        <v-list>
          <v-list-item-group v-model="selectedItem" color="indigo">
            <v-list-item>
              <v-list-item-title>
                <a
                  style="text-decoration-none; color:black;"
                  @click="hesapBilgileri"
                >
                  Hesap Bilgileri
                </a>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <a
                  style="text-decoration-none; color:black;"
                  @click="siparisBilgileri"
                >
                  Siparişlerim
                </a>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <a style="text-decoration-none; color:black;" @click="cikisYap">
                  Çıkış Yap
                </a>
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <v-btn icon @click="siparisListesiDialog = true">
        <v-icon>mdi-cart</v-icon>
        {{ cartArray.length }}
      </v-btn>
    </v-app-bar>

    <v-container>
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 mt-5">
          <v-row dense>
            <v-col cols="12" sm="8" class="pl-6 pt-6">
              <!--<small>1-12 arası ürünler</small>-->
            </v-col>
            <v-col cols="12" sm="4" class="mt-7 mb-2">
              <v-select
                class="pa-0"
                v-model="select"
                :items="options"
                style="margin-bottom: -20px;"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <div class="row text-center">
            <v-snackbar
              class="mt-10"
              v-model="snackbar.status"
              :timeout="snackbar.timeout"
              :color="snackbar.color"
              top
              right
            >
              {{ snackbar.text }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  dark
                  text
                  icon
                  v-bind="attrs"
                  @click="snackbar.status = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-snackbar>
            <div
              class="col-md-4 col-lg-3 col-sm-6 col-xs-6"
              :key="menu.id"
              v-for="menu in filteredSearchProduct"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card class="mx-auto" color="grey lighten-4">
                  <v-img
                    class="white--text align-end"
                    :aspect-ratio="16 / 9"
                    height="200px"
                    :src="menu.resim"
                  >
                    <!--<v-card-title>{{ pro.type }}</v-card-title> -->
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out white darken-2 v-card--reveal display-3 white--text"
                        style="height: 100%;"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              outlined
                              v-if="hover"
                              color="purple darken-1"
                              dark
                              v-on="on"
                              @click="addToCart"
                              :data-id="menu.id"
                              large
                            >
                              fas fa-shopping-cart
                            </v-icon>
                          </template>
                          <span>Sepete Ekle</span>
                        </v-tooltip>
                      </div>
                    </v-expand-transition>
                  </v-img>
                  <v-card-text class="text--primary">
                    <div>
                      <a style="text-decoration: none;">
                        {{ menu.urunadi }}
                      </a>
                    </div>
                    <div>{{ menu.fiyat }}{{ menu.paraBirimi }}</div>
                  </v-card-text>
                </v-card>
              </v-hover>
            </div>
          </div>
          <!--<div class="text-center mt-12">
            <v-pagination v-model="page" :length="6"></v-pagination>
          </div>
          -->
        </div>
      </div>
    </v-container>
    <v-dialog v-model="siparisListesiDialog" max-width="1000">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="mb-3">
              <v-row no-gutters>
                <v-card-title>
                  Sipariş Listesi
                </v-card-title>
                <v-card-title>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        class="ma-2 white--text"
                        fab
                        @click="sosSecimiYap"
                        v-on="on"
                        outlined
                        color="red"
                      >
                        <v-icon>mdi-gesture</v-icon>
                      </v-btn>
                    </template>
                    <span>Sos Seçimi</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        class="ma-2 white--text"
                        fab
                        @click="icecekSecimiYap"
                        v-on="on"
                        outlined
                        color="brown"
                      >
                        <v-icon>local_cafe</v-icon>
                      </v-btn>
                    </template>
                    <span>İçecek Seçimi</span>
                  </v-tooltip>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-btn
                  class="mt-3"
                  icon
                  color="grey"
                  text
                  @click="siparisListesiDialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headerSiparis"
                :items="itemsWithIndex"
                :search="searchingSiparis"
                class="elevation-1"
                :footer-props="{ itemsPerPageText: 'Siparişler' }"
              >
                <template v-slot:item.resim="{ item }">
                  <v-avatar size="40">
                    <img :src="item.resim" />
                  </v-avatar>
                </template>
                <template v-slot:item.adet="{ item }">
                  <div class="mt-6" style="width: 60px;">
                    <v-text-field
                      small
                      type="number"
                      solo
                      v-model="item.adet"
                      @click="selectedProductAdet(item)"
                    ></v-text-field>
                  </div>
                </template>
                <template v-slot:item.fiyat="{ item }">
                  {{ item.fiyat }} {{ item.paraBirimi }}
                </template>
                <template v-slot:item.toplam_fiyat="{ item }">
                  {{ item.toplam_fiyat }} {{ item.paraBirimi }}
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        outlined
                        @click="deleteSiparis(index)"
                        color="red"
                        dark
                        v-on="on"
                      >
                        mdi-close
                      </v-icon>
                    </template>
                    <span>Sil</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-layout>
                <h4>Toplam :</h4>
                <h4 class="ml-1">{{ toplamBorc }}{{ this.paraBirimi }}</h4>
              </v-layout>
            </v-card-actions>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="mr-4 mb-3"
                color="#d58f4c"
                dark
                @click="siparisiTamamla(cart)"
              >
                SİPARİŞİ TAMAMLA
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <!--
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          class="ma-2 white--text"
                          fab
                          @click="sosSecimiYap"
                          v-on="on"
                          outlined
                          color="red"
                        >
                          <v-icon>mdi-gesture</v-icon>
                        </v-btn>
                      </template>
                      <span>Sos Seçimi</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          class="ma-2 white--text"
                          fab
                          @click="icecekSecimiYap"
                          v-on="on"
                          outlined
                          color="brown"
                        >
                          <v-icon>local_cafe</v-icon>
                        </v-btn>
                      </template>
                      <span>İçecek Seçimi</span>
                    </v-tooltip>
                  </th>
                  <th class="text-center">

                  </th>
                  <th class="text-center">
                    Adet
                  </th>
                  <th class="text-center">
                   Birim Fiyat
                  </th>
                  <th class="text-center">
                   Toplam Fiyat
                  </th>
                  <th class="text-center">
                    İçindekiler
                  </th>
                  <th class="text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in cart">
                  <td class="pt-2 pb-2" colspan="2">
                    <v-avatar size="50">
                      <img :src="item.resim" />
                    </v-avatar>
                    <span class="ml-1">{{ item.urunadi }}</span>
                  </td>
                  <td class="text-center"><input type="number" id="quantity" name="quantity" min="1" max="5" :value="item.adet"><span class="ml-1">Adet</span></td>
                  <td class="text-center">{{ item.fiyat }}€</td>
                  <td class="text-center">{{ item.toplam_fiyat}}€</td>
                  <td class="text-center">{{ item.urunaciklama }}</td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          outlined
                          @click="deleteSiparis(index)"
                          color="red"
                          dark
                          v-on="on"
                        >
                          mdi-close
                        </v-icon>
                      </template>
                      <span>Sil</span>
                    </v-tooltip>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-center" colspan="2">
                    <v-row no-gutters>
                      <v-col lg="2" offset-lg2 cols="6">
                        <h4>Toplam</h4>
                      </v-col>
                      <v-col lg="3" cols="6">
                        <h4>{{ cartTotal }}€</h4>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          -->
    </v-dialog>
    <v-dialog v-model="siparisiTamamlaDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">
          Kişisel Bilgiler
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="siparisiTamamlaDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="siparis.adSoyad"
                    label="Ad Soyad"
                    prepend-icon="person"
                    color="#d58f4c"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="siparis.telefon"
                    label="Telefon"
                    prepend-icon="call"
                    color="#d58f4c"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="siparis.adres"
                    label="Adres"
                    prepend-icon="location_on"
                    color="#d58f4c"
                    rows="2"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="siparis.not"
                    label="Not"
                    prepend-icon="note_alt"
                    color="#d58f4c"
                    rows="1"
                    placeholder="İletmek istdiğiniz notunuzu ekleyebilirsiniz.."
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="#d58f4c"
            dark
            :loading="loader"
            @click="saveSiparis"
          >
            Gönder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sosSecimiYapDialog" max-width="600">
      <v-card>
        <v-card-title class="headline">
          Soslar
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="sosSecimiYapDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center" colspan="1">
                    Sos
                  </th>
                  <th class="text-center">
                    İçindekiler
                  </th>
                  <th class="text-center">
                    Fiyat
                  </th>
                  <th class="text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="sos in soslar">
                  <td class="pt-2 pb-2">
                    <v-avatar size="70">
                      <img :src="sos.resim" />
                    </v-avatar>
                    <span class="ml-1">{{ sos.urunadi }}</span>
                  </td>

                  <td class="text-center">{{ sos.urunaciklama }}</td>
                  <td class="text-center">
                    {{ sos.fiyat }}{{ sos.paraBirimi }}
                  </td>
                  <td>
                    <v-checkbox
                      v-model="sos.selectedActive"
                      @change="sosSec(sos)"
                      color="orange"
                      hide-details
                    ></v-checkbox>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="#d58f4c"
            dark
            @click="SosEkle"
            :sos-id="sos.id"
          >
            Ekle
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="icecekSecimiYapDialog" max-width="600">
      <v-card>
        <v-card-title class="headline">
          İçecekler
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="icecekSecimiYapDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center"></th>
                  <th class="text-center">
                    İçecek
                  </th>
                  <th class="text-center">
                    İçindekiler
                  </th>
                  <th class="text-center">
                    Fiyat
                  </th>
                  <th class="text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="icecek in icecekler">
                  <td class="pt-2 pb-2">
                    <v-avatar size="70">
                      <img :src="icecek.resim" />
                    </v-avatar>
                  </td>
                  <td>{{ icecek.urunadi }}</td>
                  <td>{{ icecek.urunaciklama }}</td>
                  <td>{{ icecek.fiyat }}{{ icecek.paraBirimi }}</td>
                  <td>
                    <v-checkbox
                      v-model="icecek.selectedActive"
                      @change="icecekSec(icecek)"
                      color="orange"
                      hide-details
                    ></v-checkbox>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="#d58f4c"
            dark
            @click="icecekEkle"
            :icecek-id="icecek.id"
          >
            Ekle
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="musteriSiparisDialog" max-width="1200">
      <v-card>
        <v-card-title class="headline">
          Siparişler
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="musteriSiparisDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="musteriSiparisleri"
            :search="searching"
            class="elevation-1"
            :footer-props="{ itemsPerPageText: 'Siparişler' }"
          >
            <template v-slot:item.urunler="{ item }">
              <p v-for="u in item.urunler">{{ u.urunadi }}</p>
            </template>
            <template v-slot:item.ucret="{ item }">
              {{ item.ucret }} {{ item.paraBirimi }}
            </template>
            <template v-slot:item.teslimatDurumu="{ item }">
              <v-chip
                :color="getColor(item.teslimatDurumu)"
                class="white--text"
                small
                @click="selectedSiparis(item)"
              >
                {{ item.teslimatDurumu }}
              </v-chip>
            </template>
            <template v-slot:item.action="{ item }">
              <v-icon small class="mr-2" color="green">
                mdi-pencil
              </v-icon>
              <v-icon color="red" small class="mr-2">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="siparisDetayDialog" max-width="1100">
      <v-card>
        <v-card-title class="headline">
          Sipariş Detayları
          <v-spacer></v-spacer>
          <v-btn
            right
            class="float-right mr-4"
            color="sari"
            @click="dialogClose"
            dark
          >
            Kaydet
          </v-btn>
          <v-btn icon color="grey" text @click="siparisDetayDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col sm12 md12 lg6>
                <v-card outlined>
                  <v-card-title>Siparişler</v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left"></th>
                            <th class="text-left">Adet</th>
                            <th class="text-left">
                              Ürün
                            </th>
                            <th class="text-left">
                              İçindekiler
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="urun in siparisDetayUrunler">
                            <td class="pt-2 pb-2">
                              <v-avatar size="50">
                                <img :src="urun.resim" />
                              </v-avatar>
                            </td>
                            <td>{{ urun.adet }}</td>
                            <td>{{ urun.urunadi }}</td>
                            <td>{{ urun.urunaciklama }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col sm12 md12 lg6>
                <v-card outlined>
                  <v-card-title>
                    Sipariş Durumu
                  </v-card-title>
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="4">
                        <v-radio-group
                          v-model="teslimatDurumu"
                          column
                          @change="changeSiparisDurum"
                        >
                          <v-radio
                            disabled
                            label="Hazırlanıyor"
                            color="sari darken-3"
                            value="Hazırlanıyor"
                          ></v-radio>
                          <v-radio
                            disabled
                            label="Tamamlandı"
                            color="success"
                            value="Tamamlandı"
                          ></v-radio>
                          <v-radio
                            disabled
                            label="İptal Edildi"
                            color="red darken-3"
                            value="İptal Edildi"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card outlined class="mt-5">
                  <v-card-title>Adres</v-card-title>
                  <v-card-text>
                    <v-col>
                      <v-text-field
                        cols="3"
                        rows="4"
                        v-model="adres"
                      ></v-text-field>
                    </v-col>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="2" offset="10">
                <v-btn
                  right
                  class="float-right mr-4 mb-3"
                  color="red darken-1"
                  dark
                  @click="siparisIptal"
                >
                  İptal Et
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-footer color="primary" :padless="true">
      <v-card flat tile width="100%" class="secondary white--text text-center">
        <v-card-text>
          <v-btn class="mx-4 white--text" icon>
            <v-icon size="24px">fab fa-instagram</v-icon>
          </v-btn>
          <v-btn class="mx-4 white--text" icon>
            <v-icon size="24px">fab fa-twitter</v-icon>
          </v-btn>
          <v-btn class="mx-4 white--text" icon>
            <v-icon size="24px">fab fa-facebook-f</v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text class="white--text pt-0">
          Çukurambar Ankara
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} —
          <strong>Muhabbet Sofrası</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>
<script>
import { db, fb } from '../../firebase/fb.js'
import { eventBus } from '../../main.js'
export default {
  data() {
    return {
      paraBirimi: '',
      adres: '',
      siparisDetayUrunler: [],
      siparisDetayDialog: false,
      selectedItem: 1,
      id: '',
      searchingSiparis: '',
      headerSiparis: [
        { text: 'Resim', value: 'resim' },
        { text: 'Ürün', value: 'urunadi' },
        { text: 'Adet', value: 'adet' },
        { text: 'Birim Fiyat', value: 'fiyat' },
        { text: 'Toplam Fiyat', value: 'toplam_fiyat' },
        { text: 'İçindekiler', value: 'urunaciklama' },
        { text: 'İşlem', value: 'actions' },
      ],
      headers: [
        { text: 'Tarih', value: 'tarih' },
        { text: 'Müşteri', value: 'adSoyad' },
        { text: 'Telefon', value: 'telefon' },
        { text: 'Ücret', value: 'ucret' },
        { text: 'Siparişler', value: 'urunler' },
        { text: 'Teslimat', value: 'teslimatDurumu' },
      ],
      searching: '',
      musteriSiparisleri: [],
      musteriSiparisDialog: false,
      snackbar: {
        status: false,
        text: '',
        color: '',
        timeout: 4000,
      },
      sosSecimi: [],
      selectedSos: false,
      loader: false,
      menuler: [],
      cart: [],
      soslar: [],
      siparisListesiDialog: false,
      siparisiTamamlaDialog: false,
      sosSecimiYapDialog: false,
      siparis: {
        id: '',
        adSoyad: '',
        adres: '',
        telefon: '',
        not: '',
        ucret: '',
        urunler: [],
        teslimatDurumu: 'Hazırlanıyor',
        tarih: this.formatDate(new Date().toISOString().substr(0, 10)),
        musteriDocName: '',
        paraBirimi: '',
      },
      range: [0, 10000],
      select: 'Akıllı Sıralama',
      options: [
        'Akıllı Sıralama',
        'Fiyata Göre Artan',
        'Fiyata Göre Azalan',
        'Editörün Seçimi',
        'Yeniden Eskiye',
      ],
      page: 1,
      min: 0,
      max: 10000,
      searchProductKey: '',
      siparisler: [],
      sos: {
        id: null,
        urunadi: '',
        urunaciklama: '',
        resim: '',
        docName: '',
        fiyat: '',
      },
      icecek: {
        id: null,
        urunadi: '',
        urunaciklama: '',
        resim: '',
        docName: '',
        fiyat: '',
        musteriBilgi: [],
      },
      icecekSecimiYapDialog: false,
      icecekler: [],
      docName: '888888',
      musteri: {
        mail: '',
        docName: '',
        username: '',
        password: '',
      },
      cartArray: [],
      selectedSos: [],
      selectedIcecek: [],
      toplamBorc: 0,
      product: {
        id: null,
        urunadi: '',
        urunaciklama: '',
        resim: '',
        docName: '',
        fiyat: '',
        active: '',
        activeDurum: '',
        index: null,
        adet: null,
        toplam_fiyat: 0,
      },
      siparisDocname: '',
      teslimatDurumu: '',
      tekliUrunler: [],
    }
  },

  beforeMount() {
    ;(this.id = this.$route.params.id), this.getMenu()
    this.getSiparis()
    //  eventBus.$on('changedData', (val) => {
    //    this.musteriBilgi = val
    //    this.musteri.docName = this.musteriBilgi[0].docName
    //    this.siparis.musteriDocName = this.musteri.docName
    //    this.musteri.username = this.musteriBilgi[0].username
    //    this.musteri.password = this.musteriBilgi[0].password
    //    this.musteri.mail = this.musteriBilgi[0].mail
    //   })
  },
  mounted() {
    console.log('Müsteri ID' + this.id)
  },
  methods: {
    siparisIptal() {
      this.teslimatDurumu = 'İptal Edildi'
      db.collection('bayiler')
        .doc(this.$route.params.id)
        .collection('siparisler')
        .doc(this.siparisDocName)
        .update({
          teslimatDurumu: this.teslimatDurumu,
        })
        .then(function () {
          console.log('Document successfully updated!')

          // vm.teslimatDurumu = ''
          // vm.siparisDocName = ''
        })
        .catch(function (error) {
          console.error('Error updating document: ', error)
        })
      this.snackbar.status = true
      this.snackbar.color = 'red darken-1'
      this.snackbar.text = 'Siparişiniz İptal Edilmiştir.'
      this.siparisDetayDialog = false
    },
    dialogClose() {
      console.log(this.adres)
      db.collection('bayiler')
        .doc(this.$route.params.id)
        .collection('siparisler')
        .doc(this.siparisDocName)
        .update({
          adres: this.adres,
        })
        .then(function () {
          console.log('Document successfully updated!')

          // vm.teslimatDurumu = ''
          // vm.siparisDocName = ''
        })
        .catch(function (error) {
          console.error('Error updating document: ', error)
        })
      this.snackbar.status = true
      this.snackbar.color = 'sari'
      this.snackbar.text = 'Sipariş Durumu Güncellendi'
      //this.siparisDetayDialog = false
      this.siparisDetayDialog = false
    },
    selectedSiparis(siparis) {
      console.log(siparis)
      this.siparisDetayDialog = true
      this.siparisDetayUrunler = siparis.urunler
      this.adres = siparis.adres
      this.teslimatDurumu = siparis.teslimatDurumu
      this.siparisDocName = siparis.docName
      console.log(this.siparisDocName)
    },
    selectedProductAdet(item) {
      this.product = item
      this.product.toplam_fiyat = 0
      this.product.toplam_fiyat =
        this.product.adet * parseFloat(this.product.fiyat)
      this.toplamBorc += parseFloat(this.product.fiyat)
      console.log(this.toplamBorc)
      return this.product.toplam_fiyat.toFixed(2)
      // var foundIndex = this.cart.findIndex(x => x.id == this.product.id);
      //this.cart[foundIndex] = this.product;
      //console.log(this.cart)
    },
    getColor(teslimatDurumu) {
      if (teslimatDurumu === 'Hazırlanıyor') return 'orange'
      else if (teslimatDurumu === 'İptal Edildi') return 'red'
      else if (teslimatDurumu === 'Tamamlandı') return 'green'
    },
    siparisBilgileri() {
      this.musteriSiparisDialog = true
      this.getMusteriSiparis()
    },
    getMusteriSiparis() {
      db.collection('bayiler')
        .doc(this.id)
        .collection('siparisler')
        .where('musteriDocName', '==', this.musteri.docName)
        .onSnapshot((querySnapshot) => {
          this.musteriSiparisleri = []
          querySnapshot.forEach((doc) => {
            this.musteriSiparisleri.push({ docName: doc.id, ...doc.data() })
          })
          // console.log(this.musteriSiparisleri)
        })
    },
    hesapBilgileri() {},
    cikisYap() {
      setTimeout(() => {
        this.$router.push({ path: `/customer-login/${this.$route.params.id}` })
      }, 1000)
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    getIcecekler() {
      db.collection('bayiler')
        .doc(this.id)
        .collection('icecekler')
        .where('active', '==', true)
        .onSnapshot((querySnapshot) => {
          this.icecekler = []
          querySnapshot.forEach((doc) => {
            this.icecekler.push({ docName: doc.id, ...doc.data() })
          })
          //console.log(this.soslar)
        })
    },
    icecekEkle(e) {
      var i
      var item
      var adet = 1
      var total = 0
      for (i = 0; i < this.selectedIcecek.length; i++) {
        this.selectedIcecek[i].adet = adet
        total =
          this.selectedIcecek[i].adet * parseFloat(this.selectedIcecek[i].fiyat)
        this.selectedIcecek[i].toplam_fiyat = total.toFixed(2)
        this.cart.push(this.selectedIcecek[i])
        this.cartArray.push(this.selectedIcecek[i])
      }
      this.selectedIcecek = []
      //    console.log(this.cart)
      //this.cart.push(this.sos)
      this.icecekSecimiYapDialog = false
    },
    icecekSec(icecek) {
      if (icecek.selectedActive == true) {
        this.icecek = icecek
        this.selectedIcecek.push(this.icecek)
        //   console.log(this.selectedSos)
      } else if (icecek.selectedActive == false) {
        this.icecek = icecek
        this.selectedIcecek.splice(this.icecek, 1)
        console.log(this.selectedIcecek)
      }
      // console.log(icecek)
    },
    icecekSecimiYap() {
      this.getIcecekler()
      this.icecekSecimiYapDialog = true
    },
    deleteSiparis(index) {
      this.cart.splice(index, 1)
      this.cartArray.splice(index, 1)
    },
    SosEkle() {
      var i
      var item
      var adet = 1
      var total = 0
      for (i = 0; i < this.selectedSos.length; i++) {
        this.selectedSos[i].adet = adet
        total = this.selectedSos[i].adet * parseFloat(this.selectedSos[i].fiyat)
        this.selectedSos[i].toplam_fiyat = total.toFixed(2)
        this.cart.push(this.selectedSos[i])
        this.cartArray.push(this.selectedSos[i])
      }
      this.selectedSos = []
      //    console.log(this.cart)
      //this.cart.push(this.sos)
      this.sosSecimiYapDialog = false
    },
    sosSec(sos) {
      if (sos.selectedActive == true) {
        this.sos = sos
        this.selectedSos.push(this.sos)
        //   console.log(this.selectedSos)
      } else if (sos.selectedActive == false) {
        this.sos = sos
        this.selectedSos.splice(this.sos, 1)
        console.log(this.selectedSos)
      }
    },
    getSiparis() {
      db.collection('siparisler').onSnapshot((querySnapshot) => {
        this.siparisler = []
        querySnapshot.forEach((doc) => {
          this.siparisler.push({ docName: doc.id, ...doc.data() })
        })
        //console.log(this.soslar)
      })
    },
    getSoslar() {
      db.collection('bayiler')
        .doc(this.id)
        .collection('soslar')
        .where('active', '==', true)
        .onSnapshot((querySnapshot) => {
          this.soslar = []
          querySnapshot.forEach((doc) => {
            this.soslar.push({ docName: doc.id, ...doc.data() })
          })
          //console.log(this.soslar)
        })
    },
    sosSecimiYap() {
      this.getSoslar()
      this.sosSecimiYapDialog = true
    },
    saveSiparis() {
      this.siparis.urunler = this.cart
      this.siparis.ucret = this.cartTotal
      this.siparis.paraBirimi = this.paraBirimi
      //  console.log(this.siparis.ucret)
      this.loader = true
      if (this.siparis.id == '') {
        if (this.siparisler.length > 0) {
          let id = this.siparisler[0].id
          this.siparis.id = parseInt(id) + 1
        } else {
          this.siparis.id = 1
        }
        db.collection('bayiler')
          .doc(this.id)
          .collection('siparisler')
          .add(this.siparis)
          .then((docRef) => {
            this.loader = false
            this.siparisiTamamlaDialog = !this.siparisiTamamlaDialog
            this.snackbar.status = true
            this.snackbar.color = 'teal'
            this.snackbar.text = 'Siparişiniz Alınmıştır.'
            this.cart = []
            this.cartArray = []
            this.clearSiparis()
            let vm = this
            let id = docRef.id
            db.collection('bayiler')
              .doc(this.id)
              .collection('siparisler')
              .doc(id)
              .update({
                docName: id,
              })
          })
      }
      this.mailGonder()
    },
    mailGonder() {
      //http://muhammedkaradas.yazilimca.org/mail/send_email.php
      //backend/application/controllers/Welcome.php/sendEmail
      axios
        .post('http://localhost:8000/Welcome/sendEmail', {
          id: this.siparis.id,
          urunler: this.siparis.urunler,
          ucret: this.siparis.ucret,
          mail: this.mail,
        })
        .then((response) => {
          console.log('Basarili', response)
        })
        .catch((error) => {
          console.log(error.response)
        })
    },
    clearSiparis() {
      this.siparis = {
        id: '',
        adSoyad: '',
        adres: '',
        telefon: '',
        not: '',
        ucret: '',
        urunler: [],
        teslimatDurumu: '',
        docName: '',
      }
    },
    siparisiTamamla(cart) {
      this.siparisListesiDialog = false
      this.siparisiTamamlaDialog = true
    },
    addToCart(e) {
      var i
      var item
      var adet = 0
      var total = 0
      for (i = 0; i < this.menuler.length; i++) {
        if (this.menuler[i].id == e.target.getAttribute('data-id')) {
          adet++
          this.menuler[i].adet = adet
          total = this.menuler[i].adet * parseFloat(this.menuler[i].fiyat)
          this.menuler[i].toplam_fiyat = total.toFixed(2)
          this.cart.push(this.menuler[i])
          this.cartArray.push(this.menuler[i])
          console.log(this.cart)
          var total = 0
          total = this.cart.reduce(
            (acc, item) => acc + parseFloat(item.fiyat) * item.adet,
            0,
          )
          this.toplamBorc = total.toFixed(2)

          break
        }
      }
    },
    getMenu() {
      db.collection('bayiler')
        .doc(this.id)
        .collection('urunler')
        .where('active', '==', true)
        .onSnapshot((querySnapshot) => {
          this.menuler = []
          querySnapshot.forEach((doc) => {
            this.menuler.push({ docName: doc.id, ...doc.data() })
          })
          // console.log(this.menuler)
        })
      db.collection('bayiler')
        .doc(this.id)
        .collection('menuler')
        .where('active', '==', true)
        .onSnapshot((querySnapshot) => {
          // this.menuler = []
          querySnapshot.forEach((doc) => {
            this.menuler.push({ docName: doc.id, ...doc.data() })
            this.paraBirimi = doc.data().paraBirimi
          })
          // console.log(this.menuler)
        })
    },
  },
  computed: {
    itemsWithIndex() {
      return this.cart.map((items, index) => ({
        ...items,
        index: index + 1,
      }))
    },
    cartTotal() {
      this.toplamBorc = 0
      this.toplamBorc = this.cart.reduce(
        (acc, item) => acc + parseFloat(item.fiyat) * item.adet,
        0,
      )
      return this.toplamBorc.toFixed(2)
    },
    filteredSearchProduct() {
      if (this.searchProductKey) {
        return this.menuler.filter((product) => {
          return product.urunadi
            .toLowerCase()
            .startsWith(this.searchProductKey.toLowerCase())
        })
      } else {
        return this.menuler
      }
    },
  },
  watch: {
    cart: {
      handler(val, oldVal) {
        if (val) {
          this.cartTotal
        }
        for (var i = 0; i < this.cart.length; i++) {
          if (this.cart.length > 1) {
            if (this.cart[i].docName == this.cart[i + 1].docName) {
              this.cart[i].adet = this.cart[i].adet + this.cart[i + 1].adet
              //this.cart[i].toplam_fiyat=0;
              this.cart.splice(i + 1, 1)
            }
          }
        }
      },
      deep: true,
    },
  },
}
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.8;
  position: absolute;
  width: 100%;
}
</style>
