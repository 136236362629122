<template>
  <v-row>
    <v-col cols="12">
      <v-snackbar
        class="mt-10"
        v-model="snackbar.status"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        top
        right
      >
        {{ snackbar.text }}
        <v-btn dark text icon @click="snackbar.status = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-snackbar>
      <v-card>
        <v-card-title class="mb-3">
          <v-row>
            <v-col sm="6" lg4 md6>
              <v-card-title>
                Bayi Listesi
              </v-card-title>
            </v-col>
            <v-col sm="6" lg4 offset-lg4 md6>
              <v-text-field
                v-model="searching"
                append-icon="search"
                single-line
                hide-details
                color="#53646b"
                placeholder="Arama Yap"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="bayiler"
          :search="searching"
          class="elevation-1"
          :footer-props="{ itemsPerPageText: 'Bayiler' }"
        >
          <template v-slot:item.action="{ item }">
            <v-icon color="teal" small class="mr-2" @click="editBayi(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="red" small class="mr-2" @click="deleteBayi(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col>
      <v-dialog v-model="bayiKayitDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">
            Bayi Bilgileri
            <v-spacer></v-spacer>
            <v-btn icon color="grey" text @click="bayiKayitDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="bayi.bayiAdi"
                  label="Bayi Adı"
                  prepend-icon="mail"
                  color="sari"
                ></v-text-field>
                <v-text-field
                  v-model="bayi.mail"
                  label="Mail Adresi"
                  prepend-icon="mail"
                  color="sari"
                ></v-text-field>
                <v-text-field
                  v-model="bayi.username"
                  label="Kullanıcı Adı"
                  prepend-icon="mdi-account"
                  color="sari"
                ></v-text-field>
                <v-text-field
                  v-model="bayi.password"
                  label="Şifre"
                  prepend-icon="mdi-lock"
                  color="sari"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4 mb-3"
              color="sari"
              dark
              @click="saveBayi"
              :loading="bayiKayitLoader"
            >
              Kaydet
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mb-10"
            bottom
            color="sari"
            dark
            fab
            fixed
            right
            v-on="on"
            @click="newBayi"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Yeni Bayi</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>
<script>
import { db, fb } from '../../firebase/fb'

export default {
  data() {
    return {
      headers: [
        { text: 'Kullanıcı Adı', value: 'username' },
        { text: 'Şifre', value: 'password' },
        { text: 'Mail Adresi', value: 'mail' },
        { text: 'Bayi Adı', value: 'bayiAdi' },
        { text: '', value: 'action' },
      ],
      searching: '',
      bayiKayitDialog: false,
      bayiKayitLoader: false,
      bayi: {
        id: null,
        password: '',
        mail: '',
        username: '',
        docName: '',
        bayiAdi: '',
      },
      snackbar: {
        status: false,
        text: '',
        color: '',
        timeout: 4000,
      },
      bayiler: [],
    }
  },
  mounted() {
    this.getBayi()
  },
  methods: {
    boslukSil() {
      var charMap = {
        Ç: 'C',
        Ö: 'O',
        Ş: 'S',
        İ: 'I',
        I: 'i',
        Ü: 'U',
        Ğ: 'G',
        ç: 'c',
        ö: 'o',
        ş: 's',
        ı: 'i',
        ü: 'u',
        ğ: 'g',
      }
      var str = this.bayi.bayiAdi
      var str_array = str.split('')
      for (var i = 0, len = str_array.length; i < len; i++) {
        str_array[i] = charMap[str_array[i]] || str_array[i]
      }
      str = str_array.join('')

      var clearStr = str
        .replace(' ', '')
        .replace('-', '')
        .replace(/[^a-z0-9-.çöşüğı]/gi, '')
      this.bayi.docName = clearStr
      //var res = new Array();
      //res = this.kelime.split(" ");
      //var sonuc = res.join("");
      //console.log(sonuc);
    },
    updateBayi() {
      let vm = this
      vm.bayiKayitLoader = true
      db.collection('bayiler')
        .doc(vm.bayi.docName)
        .update({
          bayiAdi: vm.bayi.bayiAdi,
          mail: vm.bayi.mail,
          username: vm.bayi.username,
          password: vm.bayi.password,
        })
        .then(function () {
          console.log('Document successfully updated!')
          vm.bayiKayitLoader = false
          vm.snackbar.status = true
          vm.snackbar.color = 'sari'
          vm.snackbar.text = 'Bayi Bilgileri Güncellendi'
          vm.bayiKayitDialog = !vm.bayiKayitDialog
          vm.clearBayi()
        })
        .catch(function (error) {
          console.error('Error updating document: ', error)
        })
    },
    editBayi(bayi) {
      console.log(bayi)
      this.bayi = bayi
      this.bayiKayitDialog = true
    },
    deleteBayi(bayi) {
      let vm = this
      vm.bayi.docName = bayi.docName
      if (confirm('Bayi Bilgisini Silmek İstediğinize emin misiniz?')) {
        db.collection('bayiler')
          .doc(vm.bayi.docName)
          .delete()
          .then(function () {
            //console.log("Document successfully deleted!");
            vm.snackbar.status = true
            vm.snackbar.color = 'red'
            vm.snackbar.text = 'Bayi Bilgileri Silindi'
            vm.clearBayi()
          })
          .catch(function (error) {
            console.error('Error removing document: ', error)
          })
      }
    },
    clearBayi() {
      this.bayi = {
        id: null,
        password: '',
        mail: '',
        username: '',
        docName: '',
        bayiAdi: '',
      }
    },
    getBayi() {
      db.collection('bayiler')
        .orderBy('id', 'desc')
        .onSnapshot((querySnapshot) => {
          this.bayiler = []
          querySnapshot.forEach((doc) => {
            this.bayiler.push({ docName: doc.id, ...doc.data() })
          })
          // console.log(this.products)
        })
    },
    newBayi() {
      this.clearBayi()
      this.bayiKayitDialog = true
    },
    saveBayi() {
      if (this.bayi.id == null) {
        if (this.bayiler.length > 0) {
          let id = this.bayiler[0].id
          this.bayi.id = parseInt(id) + 1
        } else {
          this.bayi.id = 1
        }
        this.boslukSil()
        db.collection('bayiler')
          .doc(this.bayi.docName)
          .set({
            id: this.bayi.id,
            username: this.bayi.username,
            password: this.bayi.password,
            mail: this.bayi.mail,
            docName: this.bayi.docName,
            bayiAdi: this.bayi.bayiAdi,
          })
          .then((docRef) => {
            console.log('Document written with ID', docRef)
            // this.bayiKayitLoader = false
            this.bayiKayitDialog = false
            this.snackbar.status = true
            this.snackbar.color = 'green'
            this.snackbar.text = 'Yeni Bayi Eklendi'
            // this.clearBayi();
            db.collection('icecekler').onSnapshot((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                let id = doc.id
                db.collection('bayiler')
                  .doc(this.bayi.docName)
                  .collection('icecekler')
                  .doc(id)
                  .set({ id: id, ...doc.data() })
              })
            })
            db.collection('menuler').onSnapshot((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                let id = doc.id
                db.collection('bayiler')
                  .doc(this.bayi.docName)
                  .collection('menuler')
                  .doc(id)
                  .set({ id: id, ...doc.data() })
              })
            })
            db.collection('urunler').onSnapshot((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                let id = doc.id
                db.collection('bayiler')
                  .doc(this.bayi.docName)
                  .collection('urunler')
                  .doc(id)
                  .set({ id: id, ...doc.data() })
              })
            })
            db.collection('tatlilar').onSnapshot((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                let id = doc.id
                db.collection('bayiler')
                  .doc(this.bayi.docName)
                  .collection('tatlilar')
                  .doc(id)
                  .set({ id: id, ...doc.data() })
              })
            })
            db.collection('soslar').onSnapshot((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                let id = doc.id
                db.collection('bayiler')
                  .doc(this.bayi.docName)
                  .collection('soslar')
                  .doc(id)
                  .set({ id: id, ...doc.data() })
              })
            })
          })
          .catch((error) => {
            console.error('Error adding document: ', error)
          })
        //console.log(this.product)
      } else if (this.bayi.id) {
        this.updateBayi()
      }
    },
  },
}
</script>
