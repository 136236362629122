<template>
  <v-row>
    <v-col cols="12">
      <v-snackbar
        class="mt-10"
        v-model="snackbar.status"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        top
        right
      >
        {{ snackbar.text }}
        <v-btn dark text icon @click="snackbar.status = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-snackbar>
      <v-card>
        <v-card-title class="mb-3">
          <v-row>
            <v-col sm="6" lg4 md6>
              <v-card-title>
                Sipariş Listesi
              </v-card-title>
            </v-col>
            <v-col sm="6" lg4 offset-lg4 md6>
              <v-text-field
                v-model="searching"
                append-icon="search"
                single-line
                hide-details
                color="#53646b"
                placeholder="Arama Yap"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="siparisler"
          :search="searching"
          class="elevation-1"
          :footer-props="{ itemsPerPageText: 'Siparişler' }"
        >
          <template v-slot:item.ucret="{ item }">
            {{ item.ucret }} {{ item.paraBirimi }}
          </template>
          <template v-slot:item.teslimatDurumu="{ item }">
            <v-chip
              :color="getColor(item.teslimatDurumu)"
              class="white--text"
              small
              @click="selectedSiparis(item)"
            >
              {{ item.teslimatDurumu }}
            </v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon color="red" small class="mr-2" @click="deleteSiparis(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-dialog v-model="siparisDetayDialog" max-width="1100">
      <v-card>
        <v-card-title class="headline">
          Sipariş Detayları
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="siparisDetayDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col sm12 md12 lg6>
                <v-card outlined>
                  <v-card-title>Siparişler</v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left"></th>
                            <th class="text-left">Adet</th>
                            <th class="text-left">
                              Ürün
                            </th>
                            <th class="text-left">
                              İçindekiler
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="urun in urunler">
                            <td class="pt-2 pb-2">
                              <v-avatar size="50">
                                <img :src="urun.resim" />
                              </v-avatar>
                            </td>
                            <td>{{ urun.adet }}</td>
                            <td>{{ urun.urunadi }}</td>
                            <td>{{ urun.urunaciklama }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col sm12 md12 lg6>
                <v-card outlined>
                  <v-card-title>
                    Sipariş Durumu
                  </v-card-title>
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="4">
                        <v-radio-group
                          v-model="teslimatDurumu"
                          column
                          @change="changeSiparisDurum"
                        >
                          <v-radio
                            label="Hazırlanıyor"
                            color="sari darken-3"
                            value="Hazırlanıyor"
                          ></v-radio>
                          <v-radio
                            label="Tamamlandı"
                            color="success"
                            value="Tamamlandı"
                          ></v-radio>
                          <v-radio
                            label="İptal Edildi"
                            color="red darken-3"
                            value="İptal Edildi"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card outlined class="mt-5">
                  <v-card-title>Adres</v-card-title>
                  <v-card-text>
                    <v-col>
                      <v-textarea :value="adres"></v-textarea>
                    </v-col>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="2" offset="10">
                <v-btn
                  right
                  class="float-right mr-4 mb-3"
                  color="sari"
                  @click="dialogClose"
                  dark
                >
                  Tamam
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { db, fb } from '../../firebase/fb'
export default {
  data() {
    return {
      newSiparisDialog: false,
      siparisDocName: '',
      teslimatDurumu: '',
      selectedSiparisDurum: 'false',
      siparisDetayDialog: false,
      headers: [
        { text: 'Tarih', value: 'tarih' },
        { text: 'Müşteri', value: 'adSoyad' },
        { text: 'Telefon', value: 'telefon' },
        { text: 'Ücret', value: 'ucret' },
        { text: 'Not', value: 'not' },
        { text: 'Teslimat', value: 'teslimatDurumu' },
        { text: '', value: 'action' },
      ],

      searching: '',
      siparisler: [],
      expanded: [],
      singleExpand: false,
      urunler: [],
      adres: '',
      siparisDurum: ['Hazırlanıyor', 'İptal Edildi', 'Tamamlandı'],
      snackbar: {
        status: false,
        text: '',
        color: '',
        timeout: 4000,
      },
      siparisDocname: '',
      id: '',
    }
  },
  beforeMount() {
    this.id = this.$route.params.id
  },
  mounted() {
    this.getSiparis()
  },
  methods: {
    deleteSiparis(item) {
      let vm = this
      vm.siparisDocname = item.docName
      if (confirm('Siparişi Silmek İstediğinize emin misiniz?')) {
        db.collection('bayiler')
          .doc(this.id)
          .collection('siparisler')
          .doc(vm.siparisDocname)
          .delete()
          .then(function () {
            //console.log("Document successfully deleted!");
            vm.snackbar.status = true
            vm.snackbar.color = 'red'
            vm.snackbar.text = 'Sipariş Silindi'
            vm.siparisDocname = ''
          })
          .catch(function (error) {
            console.error('Error removing document: ', error)
          })
      }
    },
    dialogClose() {
      this.siparisDetayDialog = false
    },
    changeSiparisDurum() {
      db.collection('bayiler')
        .doc(this.id)
        .collection('siparisler')
        .doc(this.siparisDocName)
        .update({
          teslimatDurumu: this.teslimatDurumu,
        })
        .then(function () {
          console.log('Document successfully updated!')

          // vm.teslimatDurumu = ''
          // vm.siparisDocName = ''
        })
        .catch(function (error) {
          console.error('Error updating document: ', error)
        })
      this.snackbar.status = true
      this.snackbar.color = 'sari'
      this.snackbar.text = 'Sipariş Durumu Güncellendi'
      //this.siparisDetayDialog = false
    },
    selectedSiparis(siparis) {
      console.log(siparis)
      this.siparisDetayDialog = true
      this.urunler = siparis.urunler
      this.adres = siparis.adres
      this.teslimatDurumu = siparis.teslimatDurumu
      this.siparisDocName = siparis.docName
    },
    getColor(teslimatDurumu) {
      if (teslimatDurumu === 'Hazırlanıyor') return 'sari'
      else if (teslimatDurumu === 'İptal Edildi') return 'red'
      else if (teslimatDurumu === 'Tamamlandı') return 'green'
    },
    getSiparis() {
      db.collection('bayiler')
        .doc(this.id)
        .collection('siparisler')
        .onSnapshot((querySnapshot) => {
          this.siparisler = []
          querySnapshot.forEach((doc) => {
            this.siparisler.push({ docName: doc.id, ...doc.data() })
          })
          console.log(this.siparisler)
        })
    },
  },
}
</script>
<style scoped>
.send {
  border-left: 4px solid #008000;
  padding: 5px;
  margin-bottom: 2px;
}
.received {
  border-left: 4px solid red;
  padding: 5px;
  margin-bottom: 2px;
}
</style>
