<template>
  <v-row>
    <v-snackbar
      class="mt-10"
      v-model="snackbar.status"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      top
      right
    >
      {{ snackbar.text }}
      <v-btn dark text icon @click="snackbar.status = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-col
      cols="6"
      sm="6"
      md="4"
      lg="3"
      v-for="product in products"
      :key="product.id"
    >
      <v-card tile class="mb-2 ml-1" color="grey lighten-4">
        <v-img
          :aspect-ratio="16 / 9"
          :src="product.resim"
          height="150px"
        ></v-img>
        <v-row no-gutters>
          <v-col cols="9">
            <v-card-subtitle>
              {{ product.urunadi }}
            </v-card-subtitle>
          </v-col>
          <v-col cols="3" class="mt-3">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  color="teal darken-1"
                  dark
                  v-on="on"
                  @click="editProduct(product)"
                >
                  edit
                </v-icon>
              </template>
              <span>Düzenle</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  color="red darken-1"
                  dark
                  v-on="on"
                  @click="deleteProduct(product)"
                >
                  delete
                </v-icon>
              </template>
              <span>Sil</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <!--
    <v-col cols="12">
      <template>
        <v-data-table
          :headers="headers"
          :items="products"
          :items-per-page="5"
          class="elevation-1"
        ></v-data-table>
      </template>
    </v-col>
    -->
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline">
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    label="Resim"
                    color="sari"
                    @change="imageUpload"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="product.urunadi"
                    label="Menü Adı"
                    prepend-icon="bookmark"
                    color="sari"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="product.urunaciklama"
                    label="Açıklama"
                    prepend-icon="gesture"
                    color="sari"
                    rows="1"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="product.fiyat"
                    label="Fiyat"
                    prepend-icon="bookmark"
                    color="sari"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    item-text="name"
                    item-value="sembol"
                    :items="paraBirimleri"
                    v-model="product.paraBirimi"
                    @change="changeParaBirimi()"
                    prepend-icon="euro"
                    label="Para Birimi"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            @click="saveProduct"
            :loading="loader"
            dark
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn icon color="grey" text @click="editDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <span class="ml-5">
                    <v-label>Resim</v-label>
                  </span>
                  <v-img
                    :src="product.resim"
                    aspect-ratio="1"
                    class="grey lighten-2 align-end ml-5"
                    max-width="300"
                    max-height="150"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          :loading="loading"
                          color="pink darken-1"
                          small
                          class="float-right"
                        >
                          <v-label>
                            <input type="file" hidden @change="updateImage" />
                            <v-icon>mdi-plus</v-icon>
                          </v-label>
                        </v-btn>
                      </template>
                      <span>Yeni Resim</span>
                    </v-tooltip>
                  </v-img>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="product.urunadi"
                    label="Menü Adı"
                    prepend-icon="bookmark"
                    color="sari"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="product.urunaciklama"
                    label="Açıklama"
                    prepend-icon="gesture"
                    color="sari"
                    rows="1"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="product.fiyat"
                    label="Fiyat"
                    prepend-icon="bookmark"
                    color="sari"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    item-text="name"
                    item-value="sembol"
                    :items="paraBirimleri"
                    v-model="product.paraBirimi"
                    @change="changeParaBirimi()"
                    prepend-icon="euro"
                    label="Para Birimi"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 mb-3"
            color="sari"
            @click="updateProduct"
            :loading="updateLoader"
            dark
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          class="mb-10"
          bottom
          color="sari"
          dark
          fab
          fixed
          right
          v-on="on"
          @click="newProduct"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Yeni Menü</span>
    </v-tooltip>
  </v-row>
</template>
<script>
import { db, fb } from '../../firebase/fb'
export default {
  data() {
    return {
      editDialog: false,
      disabled: true,
      products: [],
      snackbar: {
        status: false,
        text: '',
        color: '',
        timeout: 4000,
      },
      title: '',
      dialog: false,
      loader: false,
      updateLoader: false,
      product: {
        id: null,
        urunadi: '',
        urunaciklama: '',
        resim: '',
        docName: '',
        fiyat: '',
        active: false,
        activeDurum: 'Pasif',
        fileName: '',
        paraBirimi: '',
      },
      loading: false,
      bayiler: [],
      paraBirimleri: [
        { name: 'CHF', sembol: 'CHF' },
        { name: 'DDK', sembol: 'DDK' },
        { name: 'NOK', sembol: 'NOK' },
        { name: 'SEK', sembol: 'SEK ' },
        { name: '£', sembol: '£ ' },
        { name: '€', sembol: '€' },
      ],
    }
  },
  mounted() {
    this.getProduct()
    this.getBayiler()
  },
  methods: {
    changeParaBirimi() {
    //  console.log(this.product.paraBirimi)
    },
    deleteProduct(product) {
      let vm = this
      vm.product.docName = product.docName
      if (confirm('Menüyü Silmek İstediğinize emin misiniz?')) {
        db.collection('menuler')
          .doc(vm.product.docName)
          .delete()
          .then(function () {
            //console.log("Document successfully deleted!");
            vm.snackbar.status = true
            vm.snackbar.color = 'red'
            vm.snackbar.text = 'Menü Silindi'
            //vm.clearProduct()
          })
          .catch(function (error) {
            console.error('Error removing document: ', error)
          })
        for (var i = 0; i < this.bayiler.length; i++) {
          db.collection('bayiler')
            .doc(this.bayiler[i].docName)
            .collection('menuler')
            .doc(vm.product.docName)
            .delete()
            .then(function () {
              console.log('Document successfully updated!')
              // vm.clearProduct()
            })
        }
      }
    },
    updateImage(e) {
      let file = e.target.files[0]
      if (file) {
        this.loading = true
        this.product.fileName = file.name
        var storageRef = fb.storage().ref('images/' + file.name)
        let uploadTask = storageRef.put(file)
        uploadTask.on(
          'state_changed',
          (snapshot) => {},
          function (error) {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.product.resim = downloadURL
              let vm = this
              db.collection('menuler')
                .doc(vm.product.docName)
                .update({
                  resim: vm.product.resim,
                  fileName: vm.product.fileName,
                })
                .then(function () {
                  console.log('Document successfully updated!')
                  vm.loading = false
                })
                .catch(function (error) {
                  console.error('Error updating document: ', error)
                })
            })
          },
        )
      }
    },
    editProduct(product) {
      this.title = 'Menü Güncelleme'
      this.product = product
      this.editDialog = !this.editDialog
    //  console.log(this.product)
    },
    getProduct() {
      db.collection('menuler')
        .orderBy('id', 'desc')
        .onSnapshot((querySnapshot) => {
          this.products = []
          querySnapshot.forEach((doc) => {
            this.products.push({ docName: doc.id, ...doc.data() })
          })
          // console.log(this.products)
        })
    },
    updateProduct() {
      let vm = this
      vm.updateLoader = true
      db.collection('menuler')
        .doc(vm.product.docName)
        .update({
          urunadi: vm.product.urunadi,
          urunaciklama: vm.product.urunaciklama,
          fiyat: vm.product.fiyat,
          fileName: vm.product.fileName,
          paraBirimi: vm.product.paraBirimi,
        })
        .then(function () {
          console.log('Document successfully updated!')
          vm.updateLoader = false
          vm.snackbar.status = true
          vm.snackbar.color = 'sari'
          vm.snackbar.text = 'Menü Bilgileri Güncellendi'
          vm.editDialog = !vm.editDialog
        })
        .catch(function (error) {
          console.error('Error updating document: ', error)
        })
      for (var i = 0; i < this.bayiler.length; i++) {
        db.collection('bayiler')
          .doc(this.bayiler[i].docName)
          .collection('menuler')
          .doc(vm.product.docName)
          .update({
            urunadi: vm.product.urunadi,
            urunaciklama: vm.product.urunaciklama,
            fiyat: vm.product.fiyat,
            fileName: vm.product.fileName,
            resim: vm.product.resim,
            paraBirimi: vm.product.paraBirimi,
          })
          .then(function () {
            console.log('Document successfully updated!')
            // vm.clearProduct()
          })
          .catch(function (error) {
            console.error('Error updating document: ', error)
          })
      }
    },
    getBayiler() {
      db.collection('bayiler')
        .orderBy('id', 'desc')
        .onSnapshot((querySnapshot) => {
          this.bayiler = []
          querySnapshot.forEach((doc) => {
            this.bayiler.push({ docName: doc.id, ...doc.data() })
          })
          //console.log(this.bayiler)
        })
    },
    saveProduct() {
      this.loader = true
      //this.product.fiyat = parseFloat(this.product.fiyat)
      if (this.product.id == null) {
        if (this.products.length > 0) {
          let id = this.products[0].id
          this.product.id = parseInt(id) + 1
        } else {
          this.product.id = 1
        }
        this.boslukSil()
        db.collection('menuler')
          .doc(this.product.docName)
          .set({
            id: this.product.id,
            fiyat: this.product.fiyat,
            urunadi: this.product.urunadi,
            urunaciklama: this.product.urunaciklama,
            resim: this.product.resim,
            active: true,
            activeDurum: 'Aktif',
            fileName: this.product.fileName,
            paraBirimi: this.product.paraBirimi,
          })
          .then((docRef) => {
            console.log('Document written with ID', docRef)
            this.loader = false
            this.dialog = false
            this.snackbar.status = true
            this.snackbar.color = 'green'
            this.snackbar.text = 'Yeni Menü Eklendi'
          })
          .catch((error) => {
            console.error('Error adding document: ', error)
          })
        for (var i = 0; i < this.bayiler.length; i++) {
          db.collection('bayiler')
            .doc(this.bayiler[i].docName)
            .collection('menuler')
            .doc(this.product.docName)
            .set({
              id: this.product.id,
              fiyat: this.product.fiyat,
              urunadi: this.product.urunadi,
              urunaciklama: this.product.urunaciklama,
              resim: this.product.resim,
              active: true,
              activeDurum: 'Aktif',
              fileName: this.product.fileName,
              paraBirimi: this.product.paraBirimi,
            })
            .then(function () {
              console.log('Document successfully updated!')
              //this.clearProduct()
            })
        }
        //console.log(this.product)
      }
    },
    clearProduct() {
      this.product = {
        id: null,
        urunadi: '',
        urunaciklama: '',
        resim: '',
        fileName: '',
        paraBirimi: '',
      }
    },
    imageUpload(e) {
      let file = e
      console.log(file)
      this.product.fileName = file.name
      var storageRef = fb.storage().ref('images/' + file.name)
      let uploadTask = storageRef.put(file)

      uploadTask.on(
        'state_changed',
        (snapshot) => {},
        function (error) {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.product.resim = downloadURL
            console.log('File available at', downloadURL)
            this.disabled = false

          })
        },
      )

    },
    newProduct() {
      this.clearProduct()
      this.title = 'Yeni Menü'
      this.dialog = !this.dialog
    },
    boslukSil() {
      var charMap = {
        Ç: 'C',
        Ö: 'O',
        Ş: 'S',
        İ: 'I',
        I: 'i',
        Ü: 'U',
        Ğ: 'G',
        ç: 'c',
        ö: 'o',
        ş: 's',
        ı: 'i',
        ü: 'u',
        ğ: 'g',
      }
      var str = this.product.urunadi
      var str_array = str.split('')
      for (var i = 0, len = str_array.length; i < len; i++) {
        str_array[i] = charMap[str_array[i]] || str_array[i]
      }
      str = str_array.join('')

      var clearStr = str
        .replace(' ', '')
        .replace('-', '')
        .replace(/[^a-z0-9-.çöşüğı]/gi, '')
      this.product.docName = clearStr
      //var res = new Array();
      //res = this.kelime.split(" ");
      //var sonuc = res.join("");
      //console.log(sonuc);
    },
  },
  watch: {
    editDialog(val) {
      !val && this.clearProduct()
    },
  },
}
</script>
