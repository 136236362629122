import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import $ from 'jquery'
import VueHtml2Canvas from 'vue-html2canvas';

Vue.use(VueHtml2Canvas);

export const eventBus = new Vue();

Vue.config.productionTip = false;


new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
