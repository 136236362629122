<template>
  <v-app id="sandbox">
    <v-navigation-drawer v-model="drawer" app src="../../../public/bg.png" dark>
      <v-list></v-list>
      <v-list rounded>
        <template v-for="item in items">
          <v-list-item :key="item.text" link :to="item.route">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app flat color="#EEEEEE">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>Kontrol Paneli</v-toolbar-title>
      <v-spacer></v-spacer>
      <!--
      <span class="mt-2">
        <v-text-field
          color="#d58f4c"
          prepend-inner-icon="mdi-magnify"
          label="Ara"
        /></span>
        -->
      <v-menu transition="slide-x-transition" open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar class="mr-1" size="30" color="grey darken-1">
            <v-icon dark v-bind="attrs" v-on="on">
              mdi-account-circle
            </v-icon>
          </v-avatar>
        </template>
        <v-list dense>
          <!-- 
          <v-list-item>
            <v-list-item-title>
              <a
                style="text-decoration-none; color:black;"
                @click="hesapBilgileri"
              >
                Hesap Bilgileri
              </a>
            </v-list-item-title>
          </v-list-item>
          -->
           <v-list-item-group
        v-model="selectedItem"
        color="indigo"
      >
      <v-list-item>
            <v-list-item-title>
              <a style="text-decoration-none; color:black;" @click="cikisYap">
                Çıkış Yap
              </a>
            </v-list-item-title>
          </v-list-item>
      </v-list-item-group>
          
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-content class="grey lighten-3">
      <v-container fluid color="#EEEEEE">
        <router-view></router-view>
      </v-container>
    </v-content>

    <v-footer :inset="footer.inset" color="#EEEEEE" app>
      <v-spacer></v-spacer>
      <span class="px-4">&copy; {{ new Date().getFullYear() }} Muhabbet Sofrası</span>
      <v-spacer></v-spacer>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  props: {
    source: String,
  },
  data: () => ({
    drawer: null,
    footer: {
      inset: true,
    },
    items: [
      /*
      {
        icon: "dashboard",
        text: "Kontrol Paneli",
        route: "/"
      },
      */
      {
        icon: 'restaurant_menu',
        text: 'Menüler',
        route: '/home/menuler',
      },
      {
        icon: 'receipt',
        text: 'Ürünler',
        route: '/home/urunler',
      },
      {
        icon: 'cake',
        text: 'Tatlılar',
        route: '/home/tatlilar',
      },
      {
        icon: 'local_cafe',
        text: 'İçecekler',
        route: '/home/icecekler',
      },
      {
        icon: 'gesture',
        text: 'Soslar',
        route: '/home/soslar',
      },
      {
        icon: 'person',
        text: 'Bayiler',
        route: '/home/bayiler',
      },
  
      /**
      {
        icon: 'fas fa-bars',
        text: 'Menü',
        route: '/menu',
      },
     */
    ],
  }),
  methods:{
     cikisYap() {
      setTimeout(() => {
        this.$router.push('/')
      }, 1000)
    },
  }
}
</script>
