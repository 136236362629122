<template>
  <v-container class="container fill-height" fluid>
    <v-row>
      <v-snackbar
        class="mt-10"
        v-model="snackbar.status"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        top
        right
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-icon color="white" text v-bind="attrs" @click="snackbar = false">
            fas fa-times
          </v-icon>
        </template>
      </v-snackbar>
      <v-col cols="12">
        <v-row align="center" justify="center" class="box2">
          <v-col cols="12" class="loginBox">
            <v-form class="mt-10 mx-auto">
              <v-row no-gutters>
                <v-col cols="10" class="mx-auto" style="margin-top: 30px;">
                  <v-text-field
                    label="Kullanıcı Adı"
                    type="text"
                    solo
                    prepend-inner-icon="mdi-account"
                    v-model="login.username"
                    color="sari"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" class="mx-auto">
                  <v-text-field
                    label="Şifre"
                    type="password"
                    solo
                    prepend-inner-icon="mdi-lock"
                    v-model="login.password"
                    color="sari"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" offset="2">
                  <v-btn
                    color="sari"
                    x-large
                    dark
                    @click.native="logIn"
                    :loading="loadingUserLogin"
                  >
                    GİRİŞ YAP
                  </v-btn>
                </v-col>
                <v-col cols="4" offset="1">
                  <v-btn color="#CB3313" x-large dark @click.native="uyeOl">
                    ÜYE OL
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-dialog v-model="uyeKayitDialog" max-width="400">
            <v-card>
              <v-card-title class="headline">
                Üyelik Bilgileri
                <v-spacer></v-spacer>
                <v-btn icon color="grey" text @click="uyeKayitDialog = false">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="user.mail"
                      label="Mail Adresi"
                      prepend-icon="mail"
                      color="sari"
                    ></v-text-field>
                    <v-text-field
                      v-model="user.username"
                      label="Kullanıcı Adı"
                      prepend-icon="mdi-account"
                      color="sari"
                    ></v-text-field>
                    <v-text-field
                      v-model="user.password"
                      label="Şifre"
                      prepend-icon="mdi-lock"
                      color="sari"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="mr-4 mb-3"
                  color="sari"
                  dark
                  @click="saveUye"
                  :loading="uyeKayitLoader"
                >
                  Kaydet
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db, fb } from '../../firebase/fb'
import { eventBus } from '../../main.js'
import Musteri from './musteri.vue'
export default {
  data() {
    return {
      musteriler: [],
      uyeKayitLoader: false,
      uyeKayitDialog: false,
      LayoutShow: false,
      uyeBilgileriDialog: false,
      loaderUyeBilgi: false,
      uye_bilgi_duzenle: {
        username: '',
        password: '',
        docName: '',
      },

      uye_bilgi: [],
      uyelikDüzenleDialog: false,
      loaderMail: false,
      users: [],
      loadingUserLogin: false,
      login: {
        docName: '',
        password: '',
        mail: '',
        username: '',
      },
      snackbar: {
        status: false,
        text: '',
        color: '',
        timeout: 4000,
      },
      musteriler: [],
      sayi: '10',
      user: {
        username: '',
        password: '',
        mail: '',
        docName: '',
      },
    }
  },
  mounted() {},
  components: {
    musteri: Musteri,
  },
  methods: {
    logIn() {
      if (this.login.username != '' && this.login.password != '') {
        db.collection('musteriler')
          .where('username', '==', this.login.username)
          .where('password', '==', this.login.password)
          .onSnapshot((querySnapshot) => {
            this.musteriler = []
            querySnapshot.forEach((doc) => {
              this.musteriler.push({ docName: doc.id, ...doc.data() })
            })
            if (this.musteriler.length > 0) {
              setTimeout(() => {
                eventBus.$emit('changedData', this.musteriler)
              }, 3000)

              this.snackbar.status = true
              this.snackbar.color = 'sari'
              this.snackbar.text = 'Muhabbet Sofrasına Hoşgeldiniz'
              this.loadingUserLogin = false
              setTimeout(() => {
                this.$router.push({path:`/customer-shopping/${this.$route.params.id}`})
              }, 2000)
            } else if (this.musteriler.length == 0) {
              this.snackbar.status = true
              this.snackbar.color = 'red darken-1'
              this.snackbar.text = 'Kullanıcı Adı veya Şifre Yanlış.'
              this.clearLogin()
            }
          })
      }
    },

    saveUye() {
      if (
        this.user.mail != '' &&
        this.user.username != '' &&
        this.user.password != ''
      ) {
        //  this.loadingUserLogin = true
        //this.product.fiyat = parseFloat(this.product.fiyat)
        this.uyeKayitLoader = true
        db.collection('musteriler')
          .add(this.user)
          .then((docRef) => {
            let vm = this
            let id = docRef.id
            this.sayi = id
            console.log(this.sayi)
            //setTimeout(() => {
            //  eventBus.$emit('changedData', this.sayi)
            //}, 3000)
            db.collection('musteriler').doc(id).update({
              docName: id,
            })
            this.uyeKayitLoader = false
            this.snackbar.status = true
            this.snackbar.color = 'sari'
            this.snackbar.text = 'Üyelik Bilgileri Oluşturuldu'
            this.uyeKayitDialog = false
            //setTimeout(() => {
            //  this.$router.replace('/home')
            // }, 2000)
          })
          .catch((error) => {
            console.error('Error adding document: ', error)
          })
      } else if (this.login.mail == '' && this.login.password == '') {
        this.snackbar.status = true
        this.snackbar.color = 'red darken-1'
        this.snackbar.text = 'Mail ya da şifre boş bırakılamaz.'
        setTimeout(() => {
          this.$router.push('/')
        }, 1000)
      }
    },
    uyeOl() {
      this.uyeKayitDialog = true
    },
    sendData() {
      setTimeout(() => {
        eventBus.$emit('changedData', this.sayi)
      }, 1000)
      this.$router.push('/home')
    },
    saveMusteri() {
      if (this.login.mail != '' && this.login.password != '') {
        //  this.loadingUserLogin = true
        //this.product.fiyat = parseFloat(this.product.fiyat)

        db.collection('musteriler')
          .add(this.login)
          .then((docRef) => {
            let vm = this
            let id = docRef.id
            this.sayi = id
            console.log(this.sayi)
            setTimeout(() => {
              eventBus.$emit('changedData', this.sayi)
            }, 3000)
            db.collection('musteriler').doc(id).update({
              docName: id,
            })
            this.snackbar.status = true
            this.snackbar.color = 'sari'
            this.snackbar.text = 'Muhabbet Sofrasına Hoşgeldiniz'
            this.loadingUserLogin = false
            setTimeout(() => {
              this.$router.replace('/home')
            }, 2000)
          })
          .catch((error) => {
            console.error('Error adding document: ', error)
          })
      } else if (this.login.mail == '' && this.login.password == '') {
        this.snackbar.status = true
        this.snackbar.color = 'red darken-1'
        this.snackbar.text = 'Mail ya da şifre boş bırakılamaz.'
        setTimeout(() => {
          this.$router.push('/')
        }, 1000)
      }
    },

    clearLogin() {
      this.login = {
        username: '',
        password: '',
        mail: '',
      }
    },
  },
}
</script>

<style scoped>
.container {
  height: 100%;
  margin: 0;
  padding: 0;
  background: url('../../assets/img/muhabbetBG_eski.jpg');
  background-size: cover;
  font-family: sans-serif;
}

.box2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 430px;
  padding: 2.5rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.7);
  margin: auto auto;
  border-radius: 5px;
  box-shadow: 0 0 10px #000;
}
.loginBox {
  width: 375px;
  height: 100%;
}
.textBox {
  width: 375px;
  height: 100%;
  font-family: 'Microsoft Yi Baiti';
  color: white;
}
</style>
