<template>
  <v-container class="container fill-height" fluid>
    <v-row>
      <v-snackbar
        class="mt-10"
        v-model="snackbar.status"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        top
        right
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-icon color="white" text v-bind="attrs" @click="snackbar = false">
            fas fa-times
          </v-icon>
        </template>
      </v-snackbar>
      <v-col cols="12">
        <v-row align="center" justify="center" class="box2">
          <v-col cols="12" class="loginBox">
            <v-form class="mt-10 mx-auto">
              <v-row no-gutters>
                <v-col cols="10" class="mx-auto" style="margin-top: 30px;">
                  <v-text-field
                    label="Kullanıcı Adı"
                    type="text"
                    solo
                    prepend-inner-icon="mdi-account"
                    v-model="login.username"
                    color="sari"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" class="mx-auto">
                  <v-text-field
                    label="Şifre"
                    type="password"
                    solo
                    prepend-inner-icon="mdi-lock"
                    v-model="login.password"
                    color="sari"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" offset="4">
                  <v-btn
                    color="#CB3313"
                    x-large
                    dark
                    @click="userLogin"
                    :loading="loadingUserLogin"
                  >
                    GİRİŞ YAP
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col offset="2" cols="8">
            <a
              clickable
              style="color: #d2da24; text-decoration: none;"
              @click="uyelikBilgileriDuzenle"
            >
              Şifremi Unuttum
            </a>
          </v-col>
        </v-row>
      </v-col>
      <v-dialog v-model="uyelikDüzenleDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">
            Mail Kontrol
            <v-spacer></v-spacer>
            <v-btn icon color="grey" text @click="uyelikDüzenleDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="login.mail"
                  label="Geçerli mail adresi"
                  prepend-icon="mail"
                  color="#d2da24"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4 mb-3"
              color="#d2da24"
              @click="mailControl"
              :loading="loaderMail"
              dark
            >
              Gönder
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="uyeBilgileriDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">
            Üye Bilgileri
            <v-spacer></v-spacer>
            <v-btn icon color="grey" text @click="uyeBilgileriDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="uye_bilgi_duzenle.username"
                  label="Kullanıcı Adı"
                  prepend-icon="mdi-account"
                  color="#d2da24"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="uye_bilgi_duzenle.password"
                  label="Şifre"
                  prepend-icon="mdi-lock"
                  color="#d2da24"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4 mb-3"
              color="#d2da24"
              @click="uyeBilgiUpdate"
              :loading="loaderUyeBilgi"
              dark
            >
              Kaydet
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { db, fb } from '../../firebase/fb'
import { eventBus } from '../../main.js'

export default {
  data() {
    return {
      uyeBilgileriDialog: false,
      loaderUyeBilgi: false,
      uye_bilgi_duzenle: {
        username: '',
        password: '',
        docName: '',
      },
      uye_bilgi: [],
      uyelikDüzenleDialog: false,
      loaderMail: false,
      users: [],
      loadingUserLogin: false,
      login: {
        username: '',
        password: '',
        mail: '',
      },
      snackbar: {
        status: false,
        text: '',
        color: '',
        timeout: 4000,
      },
      bayiler:[],
      linkID:5
    }
  },
  mounted() {
   // this.getLoginList()
  },
  methods: {
    uyeBilgiUpdate() {
      let vm = this
      vm.loaderUyeBilgi = true
      db.collection('login')
        .doc(vm.uye_bilgi_duzenle.docName)
        .update({
          username: vm.uye_bilgi_duzenle.username,
          password: vm.uye_bilgi_duzenle.password,
        })
        .then(function () {
          console.log('Document successfully updated!')
          vm.loaderUyeBilgi = false
          vm.snackbar.status = true
          vm.snackbar.color = 'sari'
          vm.snackbar.text = 'Üye Giriş Bilgileri Güncellendi'
          vm.uyeBilgileriDialog = false
          vm.clearUyeBilgi()
        })
        .catch(function (error) {
          console.error('Error updating document: ', error)
        })
    },
    clearUyeBilgi() {
      this.uye_bilgi_duzenle = {
        username: '',
        password: '',
        docName: '',
      }
    },
    mailControl() {
      this.loaderMail = true
      db.collection('login')
        .where('mail', '==', this.login.mail)
        .onSnapshot((querySnapshot) => {
          this.uye_bilgi = []
          querySnapshot.forEach((doc) => {
            this.uye_bilgi.push({ docName: doc.id, ...doc.data() })
            if (this.uye_bilgi.length == 0) {
              this.snackbar.status = true
              this.snackbar.color = 'red darken-1'
              this.uyelikDüzenleDialog = false
              this.snackbar.text =
                'Sistemde kayıtlı böyle bir mail adresi bulunmamaktadır'
            } else {
              this.uyelikDüzenleDialog = false
              this.uyeBilgileriDialog = true
              this.uye_bilgi_duzenle.username = this.uye_bilgi[0].username
              this.uye_bilgi_duzenle.password = this.uye_bilgi[0].password
              this.uye_bilgi_duzenle.docName = this.uye_bilgi[0].docName
              console.log(this.uye_bilgi_duzenle.docName)
            }
          })
          //console.log(this.products)
        })
      this.loaderMail = false
    },
    uyelikBilgileriDuzenle() {
      this.uyelikDüzenleDialog = true
    },

    userLogin() {
      if (this.login.username === 'admin' && this.login.password === 'admin') {
        this.loadingUserLogin = true
        this.snackbar.status = true
        this.snackbar.color = 'sari'
        this.snackbar.text = 'Başarıyla Giriş Yaptınız'
        this.loadingUserLogin = false
        setTimeout(() => {
         this.$router.push('/home/urunler')
         // this.$router.push('/')
        }, 1000)
      } else if (
        this.login.username != 'admin' &&
        this.login.password != 'admin'
      ) {


       if (this.login.username != '' && this.login.password != '') {
        db.collection('bayiler')
          .where('username', '==', this.login.username)
          .where('password', '==', this.login.password)
          .onSnapshot((querySnapshot) => {
            this.bayiler = []
            querySnapshot.forEach((doc) => {
              this.bayiler.push({ docName: doc.id, ...doc.data() })
            })
            if (this.bayiler.length > 0) {
             // setTimeout(() => {
             //   eventBus.$emit('changedData', this.bayiler)
             // }, 500)

              this.snackbar.status = true
              this.snackbar.color = 'sari'
              this.snackbar.text = 'Muhabbet Sofrasına Hoşgeldiniz'
              this.loadingUserLogin = false
              setTimeout(() => {
                  this.$router.push({path:`/dashboard/${this.bayiler[0].docName}/home`})
                //this.$router.push('/dashboard/Bayi1/home')
                
              
              }, 1000)
            } else if (this.musteriler.length == 0) {
              this.snackbar.status = true
              this.snackbar.color = 'red darken-1'
              this.snackbar.text = 'Kullanıcı Adı veya Şifre Yanlış.'
              this.clearLogin()
            }
          })
      }
       else{
         this.loadingUserLogin = true
            this.snackbar.status = true
            this.snackbar.color = 'red'
            this.snackbar.text = 'Kullanıcı Adı veya Şifre Hatalı'
            this.loadingUserLogin = false
            this.clearLogin()
       }
        /* for (let key in this.users) {


          if (
            this.users[key].username == this.login.username &&
            this.users[key].password == this.login.password
          ) {
            this.loadingUserLogin = true
            this.snackbar.status = true
            this.snackbar.color = 'sari'
            this.snackbar.text = 'Başarıyla Giriş Yaptınız'
            this.loadingUserLogin = false
            EventBus.$emit('hesapBilgi', this.login)
            setTimeout(() => {
              this.$router.push('/dashboard/home')
            }, 1000)
          } else {
            this.loadingUserLogin = true
            this.snackbar.status = true
            this.snackbar.color = 'red'
            this.snackbar.text = 'Kullanıcı Adı veya Şifre Hatalı'
            this.loadingUserLogin = false
            setTimeout(() => {
              this.$router.push('/')
            }, 1000)
          }



        }*/
      }
    },
    clearLogin() {
      this.login = {
        username: '',
        password: '',
      }
    },
  },
}
</script>

<style scoped>
.container {
  height: 100%;
  margin: 0;
  padding: 0;
  background: url('../../assets/img/muhabbetBG_eski.jpg');
  background-size: cover;
  font-family: sans-serif;
}

.box2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 430px;
  padding: 2.5rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
  margin: auto auto;
  border-radius: 5px;
  box-shadow: 0 0 10px #000;
}
.loginBox {
  width: 375px;
  height: 100%;
}
.textBox {
  width: 375px;
  height: 100%;
  font-family: 'Microsoft Yi Baiti';
  color: white;
}
</style>
